import * as Sentry from '@sentry/browser'
import React, { useEffect } from 'react'
import { navigate } from 'gatsby'

const IndexPage = () => {
  useEffect(() => {
    navigate('/zones')
  }, [])

  return <></>
}

export default IndexPage
